@import "responsive";

@include respond-to(tv) {
	.wrap {
		padding: 0 15px; }
	.s-home1-info {
		margin-left: 0; }
	.s-form-form {
		padding: 92px 30px 24px; }
	.s-form-info-phone {
		margin-right: 20px; }
	.wrap {
		padding: 0 100px; }
	.s-header-logo {
		margin-right: 30px; }
	.s-header-menu li {
		margin-right: 30px; }
	.s-header-contacts {
		margin-right: 30px; }
	.s-header-phone {
		font-size: 21px; }
	.s-header-phone {
		margin-right: 20px; }
	.s-home-descr {
		max-width: 553px; } }

@include respond-to(desktop-wide) {

	.s-home {
		background-size: 45% auto;
		background-position: 94.5% bottom;
		padding-top: 0;
		min-height: 660px; }
	.s-benefits-title {
		font-size: 22px; }
	.s-benefits-descr {
		font-size: 15px;
		line-height: 28px; }
	.s-benefits-descr strong {
		font-size: 19px; }
	.s-benefits-list li {
		width: 25%; }
	.s-header-menu li a {
		font-size: 19px; }
	.s-busines .s-benefits-header {
		position: relative;
		z-index: 2; }
	.s-busines-title {
		font-size: 25px; }
	.s-busines-descr {
		font-size: 22px; }
	.s-busines-inner {
		max-width: 800px; }
	.s-busines-icon {
		width: 50px;
		img {
			width: 100%;
			height: auto; } }
	.s-busines-item {
		padding: 40px 25px; }
	.s-busines-item.item1 {
		padding-top: 40px; }
	.s-busines-cont {
		width: calc(100% - 50px);
		padding-left: 15px; }
	.s-busines-list li {
		height: auto; }
	.s-busines {
		background-size: 50% auto;
		background-position: right center;
		overflow: hidden; }
	.s-home1-list li {
		padding: 0 30px; }
	.s-home1-list {
		margin: 0 -30px; }
	.s-home1-inner {
		max-width: 800px; }
	.s-home1 {
		background-size: 50% auto; }
	.s-header-menu li a {
		font-size: 16px; }
	.s-footer .s-header-menu li {
		margin-right: 15px; }
	.s-home1-info {
		strong {
			display: block; } }
	.s-header-lk-btn {
		font-size: 16px; }
	.s-header-mail {
		font-size: 20px; }
	.s-header-phone {
		font-size: 18px; }
	.s-header-contacts {
		margin-right: 15px; }
	.s-header-logo {
		margin-right: 15px; }
	.s-header-menu li {
		margin-right: 10px; }
	.s-home-inner {
		padding-top: 70px; }
	.s-benefits-icon img {
		width: 91px;
		height: auto; }
	.s-benefits-icon {
		height: 113px;
		margin-bottom: -10px; }
	.s-benefits-list::after {
		top: 164px; }
	.s-benefits-title {
		font-size: 19px; }
	.s-benefits-descr {
		padding-top: 15px; }
	.s-benefits-descr {
		font-size: 14px;
		line-height: 25px; }
	.s-benefits-descr strong {
		font-size: 16px;
		display: inline; }
	.s-benefits {
		min-height: 1px;
		padding: 63px 0 100px; }
	.s-busines-descr {
		font-size: 17px; }
	.s-busines-title {
		font-size: 20px; }
	.s-busines-item.item3 {
		padding-top: 40px; }
	.s-home1-list {
		padding-top: 60px; }
	.s-home1-subtitle {
		font-size: 18px; }
	.s-home1-item.item3 {
		padding-bottom: 40px; }
	.s-home1-item {
		padding: 40px 22px; }
	.s-form-block {
		padding: 0 15px; }
	.s-form-item input {
		height: 65px;
		font-size: 19px; }
	.s-form-item button {
		width: 100%; }
	.s-form-item textarea {
		font-size: 19px;
		height: 177px; }
	.s-form-line {
		margin: 0 -15px; }
	.s-form-form {
		width: 75%; }
	.s-form-img {
		width: 25%; }
	.s-form-img img {
		margin-left: 0; }
	.s-map-title {
		font-size: 40px; }
	.s-map-list li {
		font-size: 25px; }
	.s-map-block {
		width: 463px;
		padding-top: 60px; }
	.s-map-inner {
		height: 500px; }
	.s-map-list {
		padding-top: 40px;
		padding-left: 0; } }

@include respond-to(desktop) {
	.s-benefits-list li {
		width: 50%;
		padding: 0; }
	.s-benefits-line {
		height: 2px;
		background: #FFDD4E;
		margin-bottom: -62px;
		margin-top: 62px; }
	.s-benefits-list::after {
		display: none; }
	.s-benefits-list li {
		margin-bottom: 50px; }
	.s-benefits-list {
		margin: 0;
		margin-bottom: -50px; }
	.s-header-menu li a {
		font-size: 20px; }
	.s-map-inner {
		height: 450px; }
	.s-map-title {
		font-size: 40px; }
	.s-map-block {
		padding: 50px 15px; }
	.s-map-list {
		padding-top: 30px; }
	.s-map-list li {
		font-size: 25px; }
	.s-home-descr {
		br {
			display: none; } }
	.s-header-inner {
		justify-content: center; }
	.s-header-left {
		justify-content: center;
		margin-right: 0;
		width: 100%; }
	.s-header-right {
		justify-content: center; }
	.s-busines {
		background-size: 80% auto; }
	.wrap {
		padding: 0 70px; } }
@include respond-to(tablet-large) {
	.s-header-phone {
		font-size: 20px; }
	.s-header-mail {
		font-size: 20px; }
	.s-header-phone {
		margin-right: 20px; }
	.s-home {
		min-height: 835px; }
	.s-benefits-header span::after {
		display: none; }
	.s-benefits-descr strong {
		display: inline; }
	.s-busines-icon {
		width: 70px;
		img {
			width: 70px;
			display: block; } }
	.s-busines-item {
		padding: 30px 15px; }
	.s-busines-cont {
		width: calc(100% - 70px);
		padding-left: 15px; }
	.s-busines-title {
		font-size: 24px; }
	.s-busines-descr {
		font-size: 17px; }
	.s-busines-item.item1 {
		padding-top: 30px; }
	.s-busines-item.item3 {
		padding-top: 30px;
		br {
			display: none; } }
	.s-home1-list {
		padding-top: 90px;
		margin: 0; }
	.s-home1-list li {
		width: 100%;
		padding: 0;
		+ li {
			margin-top: 30px; } }
	.s-home1-list li:nth-child(2) {
		width: 100%; }
	.s-home1-item + .s-home1-item {
		margin-top: 30px; }
	.s-home1-item.item3 {
		padding-bottom: 30px; }
	.s-home1-item.item1 {
		padding-bottom: 30px; }
	.s-home1 .s-busines-button {
		padding-left: 0;
		text-align: center; }
	.s-form-block {
		padding: 0 15px;
		width: 100%;
		+ .s-form-block {
			margin-top: 25px; } }
	.s-form-line {
		margin: 0 -15px; }
	.s-form-item + .s-form-item {
		margin-top: 25px; }
	.s-form-form {
		width: 100%;
		order: 2;
		margin-top: -150px; }
	.s-form-img {
		width: 100%;
		margin-top: 0; }
	.s-form-img img {
		margin: 0 auto;
		max-width: 170px; }
	.s-form {
		padding-bottom: 70px; }
	.s-map-block {
		position: relative;
		width: 100%;
		padding: 30px 15px;
		margin-top: -15px; }
	.s-map-inner {
		height: 250px; }
	.s-footer .s-header-menu {
		justify-content: center;
		li {
			margin-right: 14px; } }
	.s-footer-inner {
		justify-content: center; } }

@include respond-to(tablet) {
	.s-header-logo {
		margin-right: 30px; }
	.s-header-menu li {
		margin-right: 15px; }
	.s-header-lk-btn {
		span {
			display: none; } }
	.s-header-lk-btn svg {
		margin-right: 0; }
	.s-header-contacts {
		margin-right: 25px; }
	.s-header-lk-btn {
		height: 45px;
		border-radius: 10px;
		padding: 0 10px; }
	.s-busines-list li {
		width: 100%; }
	.s-busines-list {
		padding-top: 40px; }
	.s-home1-info {
		font-size: 20px; }
	.s-home1-info strong {
		font-size: 25px; }
	.s-benefits-header {
		font-size: 40px;
		line-height: 40px; }
	.s-form-item input {
		height: 60px; }
	.s-map-list li + li {
		margin-top: 30px; }
	.s-form-form {
		padding-top: 50px; }
	.s-home1 .s-benefits-header {
		padding: 51px 0 64px;
		width: 100%;
		display: block; }
	.s-benefits-list li {
		padding: 0 15px; }
	.s-benefits-item {
		margin: 0 -15px; }
	.s-benefits-descr {
		width: 100%; }
	.s-home1-inner {
		padding-bottom: 200px; }
	.wrap {
		padding: 0 30px; }
	.s-header-contacts {
		padding-top: 0; }
	.s-header-right {
		padding-top: 10px; }
	.s-header-logo {
		margin-right: 15px; }
	.s-popup-descr {
		font-size: 16px;
		text-align: left; }
	.s-home1-inner {
		padding-bottom: 375px; } }


@include respond-to(mobile) {
	.s-header-phone {
		font-size: 17px; }
	.s-header-mail {
		font-size: 17px; }
	.s-benefits-list li {
		width: 100%; }
	.s-home {
		background-size: 80% auto; }
	.s-home1-inner {
		padding-bottom: 200px; } }
@include respond-to(mobile1) {
	.s-header-menu li a {
		font-size: 15px; }
	.s-map-list li {
		font-size: 21px; }
	.s-map-title {
		font-size: 34px; }
	.s-form-form {
		padding: 92px 0 24px; }
	.s-form-item button {
		width: 100%; }
	.s-form-block {
		padding: 0 30px; }
	.s-form-item input {
		font-size: 18px; }
	.s-form-item textarea {
		font-size: 18px; }
	.s-home-title {
		font-size: 43px; }
	.s-header-contacts {
		display: block;
		margin-right: 0; }
	.s-home-inner {
		padding-top: 0; }
	.s-benefits-header {
		font-size: 35px; }
	.s-benefits-header span::before {
		display: none; }
	.s-busines-btn {
		width: 100%; }
	.s-header-logo {
		margin-right: 15px; }
	.s-home-descr {
		font-size: 21px; }
	.s-home-title {
		font-size: 39px; }
	.s-form-cont {
		margin: 0 -30px; }
	.s-form-form {
		padding: 92px 15px 24px; }
	.s-map-list {
		padding-left: 0; }
	.s-busines-title {
		font-size: 20px; }
	.s-home1-subtitle {
		font-size: 18px; }
	.s-home1-descr {
		font-size: 16px; }
	.s-busines-descr {
		font-size: 16px; }
	.s-header-logo {
		margin-right: 0;
		width: 100%;
		text-align: center; }
	.s-header-inner {
		max-width: 320px;
		margin: 0 auto; }
	.s-header-menu {
		margin-left: -73px; }
	.s-header-phone {
		font-size: 19px; }
	.s-header-mail {
		font-size: 19px; }
	.s-footer .s-header-menu {
		margin-left: 0; }
	.s-busines-icon img {
		width: 57px; } }

@include respond-to(mobile2);




